@import url("https://fonts.googleapis.com/css2?family=Muli&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@900&display=swap");

* {
  font-family: "Muli", "sans-serif";
}

:-webkit-autofill::first-line,
:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus,
:-webkit-autofill:active {
  font-family: "Muli", "sans-serif" !important;
}

body {
  margin: 0;
  padding: 0;
}

.clickable {
  cursor: pointer;
  user-select: none;
}
